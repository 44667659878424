@use '@on3/styles/variables' as var;

.carouselContainer {
  display: flex;
  justify-content: center;
  background-color: var.$focusBackground;
  padding: 10px;

  @media only screen and (max-width: 1380px) {
    overflow-x: scroll;
    justify-content: flex-start;
  }
}

.articlesContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;

  .largeArticleList {
    display: flex;
    flex-direction: column;
    gap: 50px;
  }

  .articlesListDate {
    border-radius: 15px;
    padding: 7px;
    font-weight: 600;
    font-size: 11px;
    line-height: 14px;
    letter-spacing: 1.15px;
    text-transform: uppercase;
    position: relative;
    background-color: var.$white;
    z-index: 1;
    width: 100%;
    text-align: center;
    color: #56657f;

    @media only screen and (min-width: 1200px) {
      margin-bottom: 20px;
    }

    &::before {
      content: '';
      display: block;
      height: 1px;
      background: var.$neutral;
      left: 0;
      top: 50%;
      width: calc(50% - 50px);
      position: absolute;
    }

    &::after {
      content: '';
      display: block;
      width: calc(50% - 50px);
      height: 1px;
      background: var.$neutral;
      right: 0;
      top: 50%;
      position: absolute;
    }
  }
}

// This is for the connatix video add on ksr
.videoAd > div {
  width: 100%;
}
