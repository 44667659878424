@use '@on3/styles/variables' as var;

.articleContainer {
  background: var.$focusBackground;
  border-radius: 5px;
  max-width: 100%;
  min-width: 345px;

  @media only screen and (min-width: 600px) {
    width: 100%;
    max-width: 600px;
  }
}

.articleItemContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 20px 15px;
}

.articleImage {
  border-radius: 5px 5px 0 0;
  margin-bottom: 10px;
  height: 100%;
  width: 100%;
  object-fit: cover;
  display: block;
}

.articleCategoryWrapper {
  margin-bottom: 5px;
}

.header {
  font-family: var.$archivo-narrow;
  font-weight: 600;
  font-size: 19px;
  line-height: 19px;
  letter-spacing: 0.15px;
  margin-bottom: 25px;

  @media only screen and (min-width: 961px) {
    font-weight: 600;
    font-size: 27px;
    line-height: 29px;
    letter-spacing: 1.15px;
  }
}

.articleBody {
  color: var.$neutralDark;
  font-size: 14px;
  line-height: 23px;
  letter-spacing: 0.35px;
  word-break: break-word;
}

.articleLinkWrapper {
  margin: 15px 0 20px;
}

.articleAuthorContainer {
  display: flex;
  align-items: flex-start;
  color: var.$neutralGray;
}

.articleTimeBullet {
  margin: 0 4px;
}

.articleCommentContainer {
  display: flex;
  align-items: flex-start;
}

.articleCommentIcon {
  font-size: 15px;
  color: var.$ksrBlue;
}

.articleCommentLink {
  margin-left: 4px;
  color: var.$ksrBlue;

  &:hover {
    color: var.$ksrDarkBLue;
  }
}

.author {
  color: var.$neutralGray;
}

.tabFont {
  font-size: 12px;
  letter-spacing: 2.15px;
  line-height: 14px;
}
