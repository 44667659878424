@use '@on3/styles/variables' as var;

.header {
  height: 34px;
  display: flex;
  align-items: center;
  text-align: center;
  margin-bottom: 10px;
}

.sidebarTitle {
  color: var.$night;
  display: flex;
  flex-direction: column;
  row-gap: 3px;
}
