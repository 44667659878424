@use '@on3/styles/variables' as var;

.storyListContainer {
  display: flex;
  flex-direction: row;
  padding: 20px 0 20px 50px;
  background-color: var.$focusBackground;
}

.articleContainer {
  display: flex;
  width: 300px;
  margin-right: 25px;
  text-decoration: none;
  border-radius: 5px;

  @media only screen and (min-width: 1440px) {
    margin-right: 55px;
  }

  &:hover {
    background-color: var.$container;
  }

  @media only screen and (max-width: 600px) {
    width: 215px;
    margin-right: 25px;
  }
}

.articleItemContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 5px;

  @media only screen and (max-width: 600px) {
    max-width: 105px;
  }
}

.articleImage {
  margin-right: 10px;
  width: 100px;
  height: 100px;
  border-radius: 3px;
  object-fit: cover;
}

.articleCategory {
  color: var.$neutralGray;
  text-transform: uppercase;
  font-style: normal;
  font-size: 10px;
  letter-spacing: 1.15px;
}

.articleTitle {
  font-style: normal;
  font-size: 14px;
  letter-spacing: 0.35px;
}
