@use '@on3/styles/mixins' as mix;

@use '@on3/styles/variables' as var;

.metaIcon {
  height: 1.5rem;
  width: auto;
}

.block {
  padding: 0 var.$spacing-regular;
  border-bottom: 1px solid var.$lightNeutralGray;

  .inner {
    max-width: var.$full-width;
    width: 100%;
    padding: 2rem 0;
    margin: 0 auto;

    @include mix.flex(
      $direction: column,
      $justify: center,
      $gap: 0.625rem,
      $wrap: wrap
    );

    @media only screen and (min-width: var.$tablet-width) {
      flex-direction: row;
    }

    h1,
    h2 {
      text-align: center;
      font-size: 0.75rem;
      letter-spacing: 0.65px;
      line-height: 1.2;
      text-transform: uppercase;
      color: var.$gray;
    }
  }
}
