@use '@on3/styles/variables' as var;

.tributeContainer {
  display: flex;
  flex-direction: column;
  background-color: var.$white;
  align-items: center;
  padding: 10px;
  border-radius: 5px;
}

.subHeader {
  font-size: 12px;
  letter-spacing: 2.15px;
  color: var.$neutralDark;
}

.siteName {
  padding: 10px 0;
}

.quoteContainer {
  display: flex;
  align-items: center;
  padding-top: 10px;
}

.quoteIcon {
  margin-right: 10px;
  fill: var.$neutralDark;
}

.quoteText {
  color: var.$neutralDark;
  font-size: 13px;
}
