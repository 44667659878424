.iframeContainer {
  display: flex;
  flex-direction: column;

  // sets some margin between the `allhref` link and the iframe body
  row-gap: 10px;
}

.iframeItem {
  border-radius: 10px;
}

.tabFont {
  font-size: 12px;
  letter-spacing: 2.15px;
  line-height: 14px;
}
