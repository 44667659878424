@use '@on3/styles/variables' as var;

.promo {
  max-width: var.$full-width;
  margin: var.$spacing-sm 1rem 0;
  min-height: 176px;

  @media screen and (min-width: var.$tablet-width) {
    margin: var.$spacing-sm var.$spacing-regular 0;
  }

  @media screen and (min-width: var.$mid-width) {
    min-height: 65px;
  }

  @media screen and (min-width: calc(var.$full-width + var.$spacing-lg)) {
    margin: var.$spacing-sm auto 0;
  }
}
