@use '@on3/styles/mixins' as mix;

@include mix.homelayout;

.sidebarright {
  position: relative;
}

.middleFeeds {
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-end;
}
